<template>
  <v-dialog
    v-model="dialog"
    :persistent="dirty"
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        small
        color="color3"
        text
        class="ma-0 pa-0"
        style="font-size: small"
      >Rep Field</v-btn>
    </template>
    <v-card>
      <v-toolbar color="color2 color2Text--text">
        <v-toolbar-title>Rep Field</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="color2Text" icon text @click.stop="cancel">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <loading-bar :value="loading"></loading-bar>
      <v-container>
        <v-row dense>
          <v-col cols="12">
            The "Rep Field" is the field that shows next to a team on the team list and pool sheets.
          </v-col>
          <v-col cols="12">
            <v-select
              color="color3"
              item-color="color3"
              :items="items"
              v-model="division.repField"
              label="Rep Field"
            ></v-select>
          </v-col>
        </v-row>
        <v-row dense justify="center">
          <v-col cols="10">
            <v-btn color="color3 color3Text--text"
              block
              @click.stop="save(true)"
              :disabled="!dirty || loading"
            >Save for {{division.name}}</v-btn>
          </v-col>
          <v-col cols="10">
            <v-btn color="color3 color3Text--text"
              block
              @click.stop="save(false)"
              :disabled="!dirty || loading"
            >Save for all divisions</v-btn>
          </v-col>
          <v-col cols="10">
            <v-btn color="color3 color3Text--text"
              block
              @click.stop="dialog = false"
              :disabled="!dirty || loading"
            >Just for me... Don't save</v-btn>
          </v-col>
          <v-col cols="10">
            <v-btn color="red white--text"
              block
              @click.stop="cancel"
              :disabled="loading"
            >Cancel</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['division'],
  data () {
    return {
      dialog: false,
      loading: false,
      repFieldOG: null
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    dirty () {
      return this.repFieldOG !== this.division.repField
    },
    items () {
      return [
        { text: 'Club', value: 'club' },
        { text: 'City, State', value: 'citystate' },
        { text: 'School', value: 'hs' }
      ]
    },
    tournamentDto () {
      return {
        id: this.tournament.id,
        divisions: this.tournament.divisions.map(d => {
          return {
            id: d.id,
            repField: this.division.repField
          }
        })
      }
    },
    divisionDto () {
      return {
        id: this.tournament.id,
        divisions: [
          {
            id: this.division.id,
            repField: this.division.repField
          }
        ]
      }
    }
  },
  methods: {
    save (toDivision) {
      const dto = toDivision ? this.divisionDto : this.tournamentDto
      this.loading = true
      this.$VBL.patch.tournament(dto)
        .then(response => {
          this.division.repFieldOG = this.division.repField
          this.dialog = false
        })
        .catch(err => console.log(err.response))
        .finally(() => {
          this.loading = false
        })
    },
    cancel () {
      this.division.repField = this.repFieldOG
      this.dialog = false
    }
  },
  watch: {
    dialog: function (n, o) {
      if (n) {
        this.repFieldOG = this.division.repField
      }
    }
  }
}
</script>

<style>

</style>
